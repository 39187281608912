import * as React from 'react';

import './_styles.scss';

const Footer: React.FunctionComponent = () => (
  <footer className="footer py-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 col-lg-6 px-0">
          <a><i aria-hidden="true" className="las la-map-marker"></i>Route de Cratogne 2, 3973 Venthône</a>
        </div>
        <div className="col-12 col-md-6 col-lg-6 px-0 d-flex align-items-center justify-content-center">
          <a className="footer-copy">©2022 StreamLINE-Solutions Sàrl</a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
